import React from "react";
import SEO from "../components/SEO";
import BasicBlock from "../components/blocks/BasicBlock";

import getTosData from "../queries/getTosData";

export default props => {
    const { data: tos } = getTosData();

    return (
        <>
        <SEO pageData={ tos.frontmatter } />
        <BasicBlock legalFormatting={ tos.frontmatter.legalFormatting } title={ tos.frontmatter.title } html={ tos.html } color={ tos.frontmatter.color } />
        </>
    );
};
